<template>
  <div class="group">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="recommend_box">
        <div class="group_name">限时半价</div>
        <div class="group_name_y">
          <div v-for="(item,index) in dataAll.ad" :key="index" class="group_top_img">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!--list-->
        <skeleton v-if="showLoading" type="list" />
        <div v-else class="jd_list_box">

          <div v-for="(item,index) in list" :key="index" class="jd_list" @click="btnLink(item)">
            <div v-if="item.markerUrl" class="markerUrlIcon">
              <img :src="item.markerUrl">
            </div>
            <div class="jd_list_ovs">
              <imgLoading class="jd_list_img" :src="item.goodCover" />
              <div class="sale_num" />
            </div>
            <div class="jd_list_son">
              <div class="jd_name">
                <span>{{ item.goodName }}</span>
              </div>
              <div class="jd_logo_bj">{{ item.goodsInfo }}</div>
              <div class="jd_logo_ov">
                <span class="jd_price">¥{{ item.goodVipPrice }}</span>
                <span class="jd_original_price">¥{{ item.goodPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import { halfPrice } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'
import imgLoading from '@/component/imgLoading'
import skeleton from '@/component/skeleton/index'

export default {
  name: 'Price',
  components: {
    mescroll,
    imgLoading,
    skeleton
  },
  data() {
    return {
      showLoading: false,
      fromType: 1, // 1   悦淘
      dataAll: '',
      list: '',
      mescrollDown: {
        use: true,
        top: '0px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
    this.gitAssemble()
  },
  methods: {
    gitAssemble: function() {
      this.showLoading = true
      const data = {
        fromType: this.fromType, // 1   悦淘
        page: 1,
        pageSize: 10
      }
      halfPrice(data).then(res => {
        if (Number(res.code) === 200) {
          this.dataAll = res.data
          this.list = res.data.list
          this.showLoading = false
        }
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(pdType, pageNum, pageSize, successCallback, errorCallback) {
      const data = {
        fromType: this.fromType,
        page: pageNum,
        pageSize: pageSize
      }
      halfPrice(data).then(res => {
        if (Number(res.code) === 200) {
          successCallback(res.data.list)
        }
      })
    },

    // 跳转
    btnLink(item) {
      const obj = {
        product_type: 9, // 暂时借口没有返回 这个字段 product_type
        goodId: item.goodId,
        skuId: item.productSkuId, // 缺字段
        activityId: item.activityId
      }
      this.$store.dispatch('godetail', obj)
      // this.$router.push({
      //   path:'/goodsDetailXSBJ',
      //   query:{
      //     product_type:1,
      //     goodId:item.goodId,
      //     skuId:item.productSkuId,
      //     activityId:item.activityId
      //   }
      // })
    }

  }
}
</script>

<style lang="less" scoped>
.group {
  .recommend_box {
    background: #f6f6f6;
  }

  background: #f6f6f6;

  .group_name {
    width: 100%;
    height: 80px;
    background: #a04ae0;
    color: #fff;
    font-size: 20px;
    line-height: 40px;
  }

  .group_name_y {
    width: 100%;
    height: 100px;
    border-radius: 0 0 50% 50%;
    background: #a04ae0;
    display: flex;

    .group_top_img {
      width: 90%;
      height: 140px;
      margin: -40px auto;
      border-radius: 6px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .jd_list_box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    box-sizing: border-box;
    // background: #f6f6f6;
    overflow: hidden;

    .jd_list {
      width: 169px;
      height: 305px;
      background: #fff;
      margin-right: 12px;
      float: left;
      margin-bottom: 6px;
      margin-top: 6px;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
    }

    .jd_list:nth-child(2n) {
      margin-right: 0px;
    }

    .jd_list_ovs {
      position: relative;

      .jd_list_img {
        width: 100%;
      }

      .sale_num {
        width: 100%;
        height: 16%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(97, 96, 96, 0.7));
      }

      .sale_num_style {
        position: absolute;
        right: 12px;
        bottom: 6px;
        color: #fff;
        font-size: 14px;
      }
    }

    .jd_list_son {
      padding: 0 7px;
      box-sizing: border-box;
      text-align: left;
      margin-top: 6px;

      .jd_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;

        .jd_logo {
          background: #ff001c;
          color: #fff;
          border-radius: 3px;
          padding: 0px 1px;
          font-weight: bold;
          box-sizing: border-box;
          font-size: 8px;
          margin-right: 3px;
        }
      }

      .jd_logo_ov {
        margin-top: 10px;

        .jd_price {
          color: #ff001c;
          font-size: 16px;
        }

        .jd_original_price {
          text-decoration: line-through;
          color: #bbb;
          font-size: 12px;
          margin-left: 2px;
        }
      }

      .jd_logo_bj {
        margin-top: 6px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 18px;
        font-size: 10px;
      }
    }
  }
}

.markerUrlIcon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 98;

  img {
    width: auto;
    height: 70px;
  }
}

</style>
